.profile_div {
  padding: 25px 20px 20px 20px;
  position: fixed;
  max-width: 22rem;
}



.profile-drop-down-box {
  width: 150px;
  background-color: white;
  position: absolute;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin-top: 0px;
  right: 65%;
  z-index: 99999;
}

.profile-active-dot {
  color: green;
  position: absolute;
  bottom: 38px;
  left: 15px;
  font-size: 25px;
  cursor: pointer;
}

.active_status_hover:hover {
  cursor: pointer;
}

.mnav_wid70 {
  padding-left: 25px;
}

.profile_img {
  height: 120px;
  width: 110px;
  border-radius: 10%;
  object-fit: cover;
  margin-top: -10px;
}

.profile_name {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  /* margin-top: 21px; */
  letter-spacing: 0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_desc {
  font-size: 14px;
  margin-top: -12px;
  text-transform: capitalize;
  letter-spacing: 0.4px;
  color: black !important;
}

.profile_desc .icon {
  font-size: 18px;
  position: relative;
  top: -2px;
  cursor: pointer;
}

.navgrey {
  width: 100%;
  /* height: 109px; */
  /* height: 71%; */
  /* background: #F5F5F5; */
  /* padding: 26px 40px 20px 40px; */
  border-bottom: 1px solid #E1E4E6;
  margin-bottom: 10px;
}

.fulllogo {
  /* width: 25%; */
  width: 17%;
  /* height: 70%; */
  height: 13.63%;
  padding: 18px;
  object-fit: contain;
  border: 1px solid #E1E4E6;
  position: fixed;
}

.search_box1 {
  background-color: white;
  height: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 100%;
  display: flex;
  padding: 3px 19px 6px 30px;
}

.search_box1 input {
  width: 90%;
  height: 40px;
  border: 0;
  font-size: 15px;
  outline: none;
  letter-spacing: 0.3px;
}

.search_box1 .boxicon {
  font-size: 18px;
  margin-top: 12px;
  margin-right: 8px;
  color: #a6a4a4 !important;
}

.bell_icon {
  padding: 14px;
  font-size: 51px;
  background: #ffffff;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  color: black;
  float: right;
  margin-right: 20px;
  cursor: pointer;
}

.image_icon {
  padding: 3px;
  /* font-size: 25px; */
  width: 50px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  color: black;
  float: right;
  margin-left: 20px;
  cursor: pointer;
  margin-top: -5px;
}

.logout_icon {
  padding: 15px 13px 15px 13px;
  background: #d81010;
  box-shadow: 0px 4px 10px #eeabab;
  border-radius: 10px;
  height: 51px;
  color: black;
  float: right;
  cursor: pointer;
}

.comma::after {
  content: ", ";
}

.comma:last-child::after {
  content: none;
}

.hikuhsiufi{
  height: 90px;
  padding: 0px 20px 0px 20px;
  display: flex;
  background-color: #f2f4f6;
}


.profile_desc_wrapper {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  overflow: hidden;
}