.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ftr_wrp {
  width: 80%;
  margin: auto;
  font-size: 14px;
  margin-top: 15px;
  color: #1f7e78;
}

.ftr_wrp .link_text {
  width: 80%;
  margin: auto;
  font-size: 14px;
  margin-top: 15px;
  color: #1f7e78;
  font-weight: bold;
  text-decoration: underline;
}

.env_tag_inner {
  border: 1px solid #fff;
  margin: 2px 0px;
}

.env_tag {
  position: fixed;
  z-index: 10;
  top: 14px;
  right: -39px;
  transform: rotate(45deg);
  font-size: 10px;
  background: #f28052;
  color: #fff;
  font-weight: bold;
  width: 120px;
  text-align: center;
}

.text_unset{
  text-transform: unset !important;
}