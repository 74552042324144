.group_header43 {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 12px 20px 4px 20px;
}

.add_icon1 {
  background: #1f7e78;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 55px;
  height: 50px;
}

.add_icon1 svg {
  position: relative;
  z-index: 1;
}

.add_icon1 .fileUploader {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.active_assign_now1 {
  background-color: #1f7e78 !important;
  border-radius: 12px;
  /* height: 45px; */
  border: 0;
  font-size: 15px;
  color: white;
  text-transform: capitalize;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 0.1px;
  margin-top: -1px;
  font-weight: 500;
  float: right;
}

.group_header43 img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.group_header43 h5 {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  margin-top: 8px;
  margin-left: 7px;
}

.group_header43 p {
  color: #6d6d6d;
  margin-top: -8px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 7px;
}

.group_header43 h5 button {
  background: #1f7e78;
  border-radius: 30px;
  color: white;
  border: 0;
  width: 27%;
  height: 45px;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  float: right;
  position: relative;
  top: -4px;
}

.group_hr34 {
  border-bottom: 1px solid #d7d7d7;
}

.group_ftext6 {
  color: #6d6d6d;
  font-size: 16px;
}

.group_careteambtn {
  background: #1f7e78;
  border-radius: 10px;
  color: white;
  border: 0;
  width: 30%;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 15px;
  letter-spacing: 0.3px;
}

.care_tem9 {
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
}

.singleprofile_card {
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  padding: 20px 30px 20px 30px;
}

.singleprofile_card img {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.singleprofile_card h5 {
  font-size: 15px;
  font-weight: 500;

  text-transform: capitalize;
  letter-spacing: 0.3px;
}

.singleprofile_card p {
  color: #6d6d6d;
  font-size: 13px;
  margin-top: -5px;
}

.groupFeedsScroll {
  height: 350px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 20px;
}

.messide__text.active {
  color: #1f7e78;
}

.messide_title.active {
  color: #1f7e78;
}

.overflowHidden {
  overflow: hidden;
}

.like_group {
  background-color: #1f7e78;
  color: white;
  font-size: 30px;
  border-radius: 10px;
  padding: 7px 7px 7px 7px;
  margin-right: 10px;
  cursor: pointer;
}

.like_group_email {
  /* background-color: #1f7e78; */
  color: grey;
  font-size: 18px;
  /* border-radius: 10px; */
  margin-top: 18px;
  margin-left: -40px;
  /* margin-right: 10px; */
  cursor: pointer;
}

.like_group_form {
  /* background-color: #1f7e78; */
  color: grey;
  font-size: 20px;
  position: relative;

  /* border-radius: 10px; */
  margin-top: 15px;
  margin-left: -80px;
  /* margin-right: 10px; */
  cursor: pointer;
}

.like_group_red {
  background-color: red;
  color: white;
  font-size: 30px;
  border-radius: 10px;
  padding: 7px 7px 7px 7px;
  margin-right: 10px;
  cursor: pointer;
}

.like_group_yellow {
  background-color: yellow;
  color: black;
  font-size: 30px;
  border-radius: 10px;
  padding: 7px 7px 7px 7px;
  margin-right: 10px;
}

.event-none {
  pointer-events: auto;
}

.memlist_scroll .card {
  background: white;
  cursor: pointer;
}

.memlist_scroll .card.active {
  background: #1f7e78;
  color: white;
}

.memlist_scroll::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Optional: show position indicator in red */
.memlist_scroll::-webkit-scrollbar-thumb {
  background: white;
}

.memlist_scroll:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 7px;
}

.btn-customs button,
.btn-customs button:hover {
  background-color: #1f7e78;
  border-color: #1f7e78;
}

.btn-customs a,
.btn-customs a:hover,
.btn-customs a:visited {
  background-color: #fff !important;
  border-color: #1f7e78;
  color: #1f7e78;
}

/* new css for group activity */

.group_backwhite {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
  background-color: white;
  padding: 15px;
  width: 100%;
  border-radius: 12px;
}

.group_backwhite .groupname .name {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.group_backwhite .groupname img {
  height: 150px;
  object-fit: cover;
  width: 100%;
}

.group_backwhite .groupname .gparagraph {
  color: #7a7a7a;
  /* text-align: center; */
}

.group_backwhite .groupname .gname_links4 {
  color: #1f7e78;
  font-size: 15px;
  cursor: pointer;
  text-decoration: underline;
}

.quick_updates3 {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.quick_updateitem img {
  height: 53px;
  width: 53px;
  object-fit: cover;
  border-radius: 50%;
}

.quick_updateitem .fname {
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5%;
  margin-top: 2px;
}

.quick_updateitem .fname .icon {
  float: right;
  position: relative;
  top: 6px;
  color: #ada4a5;
  font-size: 23px;
}

.quick_updateitem .fpara {
  color: #ada4a5;
  margin-left: 5%;
  font-size: 15px;
  margin-top: -15px;
}

.quick_updateitem .itemcontent {
  color: #808080;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.2px;
}

.quick_updateitem .glike_comment {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

.quick_updateitem .glike_comment .icon1 {
  color: #1f7e78;
  cursor: pointer;
}

.quick_updateitem .glike_comment .icon2 {
  color: #808080 !important;
}

.quick_updateitem .glike_comment .icon3 {
  color: #808080;
}

.quick_updateitem .gexpress {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  cursor: pointer;
}

.gact_topcard {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
  padding: 20px 15px 7px 25px;
  width: 100%;
  border-radius: 12px;
  color: white;
}

.gact_topcard p {
  text-transform: capitalize;
}

.gcare_team45 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.gcare_team45 div {
  padding: 10px;
}

.gcare_team45 div img {
  object-fit: cover;
  max-height: 120px;
  width: 120px;
  border-radius: 50%;
}

.gcare_team45 div p {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  text-transform: capitalize;
}

.group_topuser img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #1f7e78;
}

.group_topuser p {
  font-size: 17px;
  font-weight: 500;
  color: #1f7e78;
  text-transform: capitalize;
}

.group_topuser p span {
  color: #b5b5b5;
}

.group_topuser p span .icon {
  color: #1f7e78;
  position: relative;
  top: -2px;
}

.topuser_div09 {
  background-color: #fbfbfb;
  padding: 10px 23px 10px 23px;
}

.singlepost_image43 {
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.singlepost_text {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  cursor: pointer;
}

.single_postlink00 {
  line-height: 37px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.single_postlink00 a {
  position: relative;
  top: 12px;
  margin-right: 10px;
  border: 2px dashed #d9d9d9;
  border-radius: 5px;
  color: #1f7e78;
  padding-left: 15px;
}


.poll_bartext{
  margin-top: -10px;
  font-size: 14px;
  font-weight: 500;
}

.dropdown-toggle.custom::after {
  content: none;
}

.play_btn765{
  position: absolute;
  height: 100px;
  top: 34.5%;
  left: 42%;
  background-color: white;
  border-radius: 50%;
}