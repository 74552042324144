.actsearch_box2 {
  background-color: #f0f0f0;
  height: 45px;
  border-radius: 34px;
  width: 100%;
  display: flex;
  padding: 3px 19px 6px 30px;
  border: 0;
  outline: none !important;
}
.care_icons {
  font-size: 20px;
  margin-right: 15px;
}

.careplan_icontop {
  margin-top: 12px;
}

.careplan_icontop2 {
  margin-top: 34px;
}

.checkbox_care {
  position: relative;
  accent-color: #1f7e78;
  width: 19px;
  height: 19px;
  top: 6px;
}

.checkbox_caref1 {
  position: relative;
  accent-color: #1f7e78;
  width: 24px;
  height: 24px;
  top: 36px;
}

.goal_table {
  position: relative;
  top: -10px;
}
.goal_table2 {
  position: relative;
  top: -20px;
}

.care_title {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  color: black;
  margin-top: 6px;
  letter-spacing: 0.2px;
}

.active_care {
  color: #1f7e78 !important;
  font-weight: 500;
}
.care_textdiv {
  background: #fffefe;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  width: 100%;
  padding: 10px 50px 0px 40px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.create_newplan {
  background: #1f7e78;
  border-radius: 34px;
  height: 50px;
  border: 0;
  font-size: 15px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  float: right;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 0.3px;
  margin-left: 100px;
  margin-top: -142px;
  font-weight: 500;
}

.careplan_addtask {
  background: #1f7e78;
  border-radius: 34px;
  height: 45px;
  border: 0;
  font-size: 15px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 22px;
  padding-right: 22px;
  letter-spacing: 0.3px;
  font-weight: 500;
  left: 20px;
  position: relative;
  margin-top: 0px;
}

.careplan_addmore23 {
  background: #1f7e78;
  border-radius: 10px;
  height: 50px;
  border: 0;
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 42px;
  padding-right: 42px;
  letter-spacing: 0.3px;
  font-weight: 500;
  left: 20px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
}
.care_margin {
  margin-top: -17px !important;
  margin-left: 0px !important;
}
.button_text {
  margin-top: 12px;
}

.table_resinner4 tbody tr td {
  font-size: 15px;
  color: black;
}

/* .careplan_finalsave {
  background: #1f7e78;
  border-radius: 10px;
  height: 55px;
  border: 0;
  font-size: 17px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  font-weight: 500;
width: 36%;
  margin-top: 15px;
} */

.caretask_div99 {
  background: #fffefe;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  width: 100%;
  padding: 10px 12px 10px 11px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.caretask_allgoal3 {
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  margin-top: 6px;
  letter-spacing: 0.2px;
  margin-left: 15px;
  width: 92%;
}

.caretask_allgoal3 button {
  background: #1f7e78;
  border-radius: 30px;
  height: 40px;
  border: 0;
  font-size: 15px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
  float: right;
  position: relative;
  top: -7px;
}

.caretask_carddiv2 {
  padding: 0px 15px 0px 15px;
}

.caretask_hr45 {
  border-top: 1px solid #ccc9c9;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.caretask_cardp1 {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  margin-top: 0px;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.caretask_cardpnew {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  margin-top: 0px;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  margin-left: 8%;
  width: 92%;
}

.caretask_cardp3 {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #1f7e78;
  margin-top: 0px;
  letter-spacing: 0.2px;
}

.caretask_cardp2 {
  font-size: 14px;
  text-transform: capitalize;
  color: black;
  margin-bottom: 4px;
  letter-spacing: 0.2px;
}

.caretask_scroll00 {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.caretask_scroll00::-webkit-scrollbar {
  width: 8px;
  background: white;
}
/* Optional: show position indicator in red */
.caretask_scroll00::-webkit-scrollbar-thumb {
  background: white;
}
.caretask_scroll00:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 7px;
}

.checkbox_access45 {
  position: relative;
  accent-color: #1f7e78;
  width: 21px;
  height: 21px;
  top: -6px;
}
.button_text {
  margin-top: 12px;
}

.button_top {
  margin-top: 55px;
}

.careplan_filecard {
  width: 99%;
  background: #fffefe;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  padding: 20px 20px 20px 40px;
}

.careplan_filecard img {
  padding: 3px;
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 10px;
  color: black;
  cursor: pointer;
  margin-top: 5px;
}

.upload_new_file12 {
  background: #1f7e78;
  border-radius: 10px;
  height: 60px;
  border: 0;
  font-size: 17px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  width: 47%;
  letter-spacing: 0.3px;
  font-weight: 500;
  left: 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}
