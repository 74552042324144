.react-tabs__tab-list {
  /* border-bottom: 0 !important; */
  margin: 0 0 10px !important;
  padding: 0;
  display: flex;
}

.react-tabs__tab-list.custom {
  /* border-bottom: 0 !important; */
  margin: 10px 0 !important;
  padding: 0;
  display: flex;
}

.react-tabs__tab {
  display: inline-block;
  border: 0 !important;
  /* border-bottom: none; */
  bottom: -1px;
  color: #9f9f9f;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  min-width: 2%;
  cursor: pointer;
  font-weight: 500 !important;
  /* text-transform: capitalize !important; */
  margin-right: 6%;
}

.react-tabs__tab--selected {
  background: #fff;
  /* border-color: #aaa; */
  color: #1f7e78;
  /* border-radius: 5px 5px 0 0; */
  padding-bottom: 15px;
  border-bottom: 4px solid #1f7e78 !important;
}

.react-tabs__tab--selected:focus::after {
  background-color: transparent;
}

.elipssis_active_program {
  max-width: 126px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .react-tabs__tab--selected {
  background: none !important;
  border-color: none !important;
  color: #1f7e78 !important;
  border-radius: 5px 5px 0 0;
} */

.new_multiform .react-tabs__tab:not(.react-tabs__tab--selected ~ .react-tabs__tab) {
  background: none !important;
  border-color: none !important;
  color: #1f7e78 !important;
  border-radius: 5px 5px 0 0;
}

.new_multiform .react-tabs__tab,
.new_multiform .react-tabs__tab--selected,
.new_multiform .react-tabs__tab-list {
  border-bottom: none !important;
}

.react-tabs__tab:focus {
  outline: none;
}

.active_msearch6 {
  background-color: #f0f0f0;
  max-height: 40px !important;
  border-radius: 34px !important;
  width: 100%;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 2%;
}

.actsearch_box1 {
  background-color: #f0f0f0;
  height: 50px;
  border-radius: 34px;
  width: 100%;
  display: flex;
  padding: 3px 19px 6px 30px;
  margin-left: 2%;
  border: 1.5px solid #c8c6c6;
}

.actsearch_simple {
  /* background-color: #f0f0f0; */
  border: 1px solid #f0f0f0;
  height: 50px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3px 20px 6px;
  margin-left: 0;
}

.actsearch_medi {
  background-color: #f0f0f0;
  border: 1px solid #F1F3F5;
  height: 50px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3px 20px 6px;

  margin-left: 10;
}

.actsearch_box1 input {
  width: 90%;
  height: 40px;
  border: 0;
  font-size: 15px;
  outline: none;
  letter-spacing: 0.3px;
  background-color: #f0f0f0;
}

.actsearch_simple input {
  width: 90%;
  height: 40px;
  border: 0;
  font-size: 15px;
  outline: none;
  letter-spacing: 0.3px;
}

.actsearch_medi input {
  background-color: #f0f0f0;
  width: 90%;
  height: 40px;
  border: 0;
  font-size: 15px;
  outline: none;
  letter-spacing: 0.3px;
}

.actsearch_box1 .boxicon {
  font-size: 18px;
  margin-top: 12px;
  margin-right: 8px;
  color: #a6a4a4 !important;
}

.create_newprogram {
  background: #f0f0f0;
  border-radius: 34px;
  height: 50px;
  border: 0;
  font-size: 15px;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
  float: right;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 0.3px;
}

.create_newprogram:hover {
  background: #1f7e78 !important;
  color: #f0f0f0 !important;
}

.create_bottomhr {
  border-bottom: 1px solid #cbcbcb;
  margin-top: 30px;
}

.new_multiform .active_dummyimage,
.active_dummyimg {
  height: 290px;
  width: 100%;
  border-radius: 5px;
  background-color: #FBFBFB;
  border: 2px dashed #d9d9d9;
  object-fit: contain;
}

.active_dummyimg_multi {
  height: 100px;
  width: 100px;
  border-radius: 20px;
  background-color: whitesmoke;
  border: 2px dashed #cbcaca;
  object-fit: cover;
  padding: 10px;
}

.active_dummyimage {
  height: 290px;
  width: 100%;
  border-radius: 20px;
  background-color: whitesmoke;
  border: 2px dashed #cbcaca;
  object-fit: contain;
}

.uploader-input-add {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  margin-left: 25.8%;
  margin-top: -40px;
}

.uploader-input {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  top: 0;
  margin-left: auto;
  margin-right: 0;
  z-index: 2;
  /* margin-top: -40px; */
}

.uploader-input_multi {
  position: relative;
  height: 100px;
  width: 80px;
  border-radius: 20px;
  background-color: whitesmoke;
  border: 2px dashed #cbcaca;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploader-input_multi svg {
  position: relative;
  z-index: 1;
}

.multi-uploader {
  width: 100%;
  overflow-x: auto;
  padding: 10px 0;
  background-color: #f9f9f9;
  padding: 20px 20px;
  border-radius: 5px;
}

.close-button {
  content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='red' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M331.3 308.7L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-15.6-7.1-22.6 0-7.1 7.1-6 16.6 0 22.6l52.7 52.7-52.7 52.7c-6.7 6.7-6.4 16.3 0 22.6 6.4 6.4 16.4 6.2 22.6 0l52.7-52.7 52.7 52.7c6.2 6.2 16.4 6.2 22.6 0 6.3-6.2 6.3-16.4 0-22.6z'%3E%3C/path%3E%3Cpath d='M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z'%3E%3C/path%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -12px;
  margin-right: -12px;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploader-mask {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-left: 88%;
  margin-top: -40px;
}

.upload-icon {
  font-size: 60px;
  color: #1f7e78;
  box-shadow: 0px 4px 20px #57b7b1;
  border-radius: 50%;
}

.upload-icon-x {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 1.4em;
  color: #1f7e78;
  border-radius: 50%;
}

.description_inputMedi {
  height: 65px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #d2d2d2;

  padding-left: 25px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: #9f9f9f;
  /* text-transform: capitalize; */
  margin-bottom: 20px;
  /* color: #9f9f9f; */
  background-color: #fff;
}

.description_inputMedi1 {
  height: 65px;
  width: 100%;
  border: none;
  /* border-bottom: 2px solid #d2d2d2; */

  padding-left: 5px;
  padding-right: 5px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: #9f9f9f;
  /* text-transform: capitalize; */
  margin-bottom: 20px;
  /* color: #9f9f9f; */
  background-color: #fff;
}

.show .dropdown-menu {
  display: block;
  position: absolute;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #1f7e78;
}

.description_inputForm {
  height: 65px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #d2d2d2;

  padding-left: 25px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: #9f9f9f;
  margin-top: -35px;
  /* text-transform: capitalize; */
  margin-bottom: 20px;
  /* color: #9f9f9f; */
  background-color: #fff;
}

.description_inputf {
  height: 55px;
  width: 100%;
  border: 2px solid #d2d2d2;
  border-radius: 5px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  /* color: #9f9f9f; */
  background-color: #f8f8f8 !important;
  color: black !important;
  border: 2px solid #d2d2d2;
  /* text-transform: capitalize; */
  margin-bottom: 35px;
  /* color: #9f9f9f; */
  /* background-color: rgb(217 217 217 / 15%); */
}

.description_inputf:disabled {
  background-color: #D9D9D9 !important;
  color: #D9D9D9 !important;
}

select.description_inputf {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.description_inputf::placeholder {
  color: #9f9f9f;
}

.description_descpf {
  height: auto;
  padding-left: 25px;
  padding-top: 15px;
}

.description_btnsave {
  background: #1f7e78;
  border-radius: 4px;
  height: 50px;
  border: 0;
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  /* width: 15%; */
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 20px;
  letter-spacing: 0.4px;
}

.description_btntoday {
  background: #1f7e78;
  border-radius: 4px;
  height: 55px;
  border: 0;
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  /* width: 15%; */
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 20px;
  letter-spacing: 0.4px;
}

.description_btnsave-white {
  border-radius: 4px;
  height: 50px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  /* width: 40%; */
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 20px;
  letter-spacing: 0.4px;

  background-color: rgb(255, 255, 255);
  color: black;
  border: 1px solid rgb(187, 185, 185);
}

.description_disable {
  background: #bfbfbf;
}

.description_savemodule {
  background: #1f7e78;
  border-radius: 10px;
  height: 60px;
  border: 0;
  font-size: 17px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  width: 43%;
  letter-spacing: 0.4px;
}

.description_addanother {
  background: #1f7e78;
  border-radius: 10px;
  height: 60px;
  border: 0;
  font-size: 17px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  width: 63%;
  letter-spacing: 0.4px;
}

.description_createsess {
  background: #1f7e78;
  border-radius: 10px;
  height: 60px;
  border: 0;
  font-size: 17px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  width: 33%;
  letter-spacing: 0.4px;
}

.forums_textdiv {
  background: #fffefe;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  width: 100%;
  padding: 20px 0px 10px 35px;
  margin-bottom: 15px;
}

.forums_text {
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: black;
  margin-top: -17px;
  letter-spacing: 0.2px;
}

.forums_text22 {
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: black;
  margin-top: -11px;
  letter-spacing: 0.2px;
}

.forums_profile8 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  float: right;
  margin-right: -5px;
}

.editprogram_server {
  font-size: 16px;
  color: black;
  letter-spacing: 0.4px;
  font-weight: 200;
  margin-top: 15px;
  margin-bottom: 0;
}

.editprogram_server span {
  color: black;
  font-weight: 500
}

.group_image {
  height: 80%;
  width: 100%;
  border-radius: 5%;
  object-fit: cover;
  float: right;
  margin-right: 5px;
  margin-bottom: 20px;
  padding: 8px 15px 8px 15px;
  margin-top: -15px;
}

.forums_sendmess {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  letter-spacing: 0.2px;
  margin-top: -5px;
}

.forums_iconspan {
  margin-top: -15px;
}

.forums_iconspan img {
  background: #1f7e78;
  border-radius: 15px;
  padding: 8px 15px 8px 15px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
}

.forums_iconspan span {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  letter-spacing: 0.2px;
  position: relative;
  top: 4px;
}

.forums_iconspan .icon {
  font-size: 20px;
  margin-top: 3px;
  color: #565656;
  margin-left: 10px;
}

.memberside_list {
  background: #fffefe;
  box-shadow: 0px 4px 20px rgba(204, 201, 202, 0.5);
  border-radius: 12px;
  /* min-height: 440px; */
  padding: 20px;
  /* position: relative; */
  top: 20px;
  position: sticky;
  height: 94vh !important;
}

.memberside_list .actsearch_box1 {
  margin-left: 0%;
}

.member_listimage {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.member_listimage2 {
  height: 150px;
  width: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.member_listimage1 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* object-fit: cover; */
}

.squre_image2 {
  border-radius: 0px !important;
}

.member_listitle {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  margin-top: 12px;
  letter-spacing: 0.2px;
  margin-left: 12px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 25px;
}

.memlist_scroll {
  height: 75.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.spacing_scroll {
  padding-right: 7px;
  margin-right: -15px;
}

.list_scroll {
  height: 75.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list_scroll .card * {
  pointer-events: none;
}

.memlist_scroll .card {
  background: white;
  cursor: pointer;
}

.list_scroll .card.active,
.memlist_scroll .card.active {
  background: #1f7e78;
  color: white;
}

.memlist_scroll::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Optional: show position indicator in red */
.memlist_scroll::-webkit-scrollbar-thumb {
  background: white;
}

.memlist_scroll:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  /* border-radius: 7px; */
}

.member_plusadd {
  color: #1f7e78;
  font-size: 55px;
  position: absolute;
  right: 27px;
  box-shadow: 0px 4px 20px #57b7b1;
  border-radius: 50%;
  bottom: 35px;
}

.member_plusadd_new {
  color: #1f7e78;
  font-size: 48px;
  box-shadow: 0px 4px 20px #57b7b1;
  border-radius: 50%;
  margin-left: 20px;
}

.datalist {
  max-height: 165px;
  width: 39.4%;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.095) !important;
  z-index: 9999;
  position: absolute;
  overflow-y: scroll;
  padding: 20px 20px 20px 20px;
  margin-top: 5px;
  border-radius: 15px;
  margin-left: 1%;
}

.datalist::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Optional: show position indicator in red */
.datalist::-webkit-scrollbar-thumb {
  background: white;
}

.datalist:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 7px;
}

.datalist p {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  cursor: pointer;
}

.datalist p:hover {
  color: #1f7e78;
}

.datalist p .icon {
  font-size: 15px;
  color: #1f7e78;
  position: relative;
  top: -1px;
  margin-right: 16px;
}

.act_modulbtn {
  width: 100%;
  position: relative;
}

.act_modulbtn .boxmod {
  position: absolute;
  right: 20px;
  top: 17px;
}

.act_modulbtn .delete {
  margin-left: 10px;
  color: red;
  font-size: 19px;
  cursor: pointer;
}

.act_modulbtn .add {
  color: #1f7e78;
  font-size: 19px;
  cursor: pointer;
}

/* .accordion-button:not(.collapsed){
  outline: white !important;
  background-color: white!important;
  box-shadow: none!important;
  color:white!important;
 
}
.accordion-button:after {
  display: none;
 } */

.active_msearch6 .css-1s2u09g-control {
  width: 100%;
  border: 0 !important;
  border-radius: 30px !important;
  font-size: 15px;
  font-weight: 400 !important;
  outline: none;
  text-transform: capitalize;
  margin-bottom: 20px !important;
  color: #d2d2d2;
  background-color: #f0f0f0 !important;
  padding: 7px 15px 7px 17px !important;
}

.active_msearch6 .css-1pahdxg-control {
  padding: 7px 15px 7px 17px !important;
  width: 100%;
  font-weight: 400 !important;
  outline: none !important;
  border: 0 !important;
  border-radius: 30px;
  margin-bottom: 20px;
  outline: none !important;
  color: #d2d2d2 !important;
  background-color: #f0f0f0 !important;
}

.active_n0data2 {
  font-size: 16px;
  font-weight: 500;
  margin: 0px 20px 20px 20px;
}

.disable_editmodule {
  background-color: #f0f0f0;
  height: 35px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 30px;
  color: black;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
  letter-spacing: 0.4px;
}

.accordion-item {
  border: 0;
}

.active_forrumaccordian {
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  margin-bottom: 20px;
  margin-left: 11%;
  padding: 9px 10px 5px 45px;
  background-color: #f3f3f3;
}

.activeforum_chat {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.activeforum_chat input {
  height: 50px;
  box-shadow: 0px 0px 15px rgba(55, 55, 55, 0.11);
  border-radius: 34px;
  width: 100%;
  border: 0;
  font-size: 14px;
  outline: none;
  font-weight: 500;
  color: black;
  padding-left: 35px;
  padding-right: 35px;
  letter-spacing: 0.3px;
  margin-right: 10px;
}

.activeforum_chat img {
  background-color: #1f7e78;
  padding: 13px;
  border-radius: 50%;
  height: 50px;
}

.activeforum_chat button {
  border: 0;
  background: none;
}

.Module_pright {
  padding-right: 90px;
}

.waiting_approvelist {
  background-color: #f0f0f0;
  color: black;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  padding: 7px;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
}

.waiting_icon6 {
  border: 0;
  background: none;
}

.waiting_icon6 .icon1 {
  margin-right: 23px;
  position: relative;
  top: 1px;
  color: #1f7e78;
  font-size: 22px;
  cursor: pointer;
}

.clock_icon {
  height: 24px;
  position: absolute;
  top: 18px;
  right: 17px;
}

.editbtn_active_program {

  background: #1f7e78;
  border-radius: 10px;
  color: white;
  border: 0;
  width: 91%;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 0px !important;

}

.activeeditbtn_back {
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 15px 0px 15px 0px;
  width: 100%;
}



.active_program-position {
  position: relative;
}

/* Multistep Tabs */
.new_multiform .react-tabs__tab-list {
  counter-reset: section;
  /* padding-bottom: 20px; */
}

.new_multiform .react-tabs__tab {
  position: relative;
  padding-left: 3.2em;
  margin-right: 0;
  padding-right: 4em;
}

.new_multiform .react-tabs__tab::before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  /* background-color: #333; */
  display: grid;
  place-items: center;
  color: #888;
  width: 40px;
  height: 40px;
  border: solid 1px #9f9f9f;
}

/* .new_multiform .react-tabs__tab--selected::before {
  background-color: #1f7e78;
  border: none;
  color: white;
} */
.new_multiform .react-tabs__tab:not(.react-tabs__tab--selected ~ .react-tabs__tab)::before {
  background-color: #1f7e78;
  border: none;
  color: white;
}

.new_multiform .react-tabs__tab::after,
.new_multiform .react-tabs__tab:focus:after {
  content: url("data:image/svg+xml,%3Csvg stroke='grey' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1.2em' width='1.2em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  right: 0 !important;
  margin-left: auto;
  margin-right: 0;
  display: grid;
  place-items: center;
  color: #888 !important;
  width: 40px;
  height: 40px;
  margin-top: 2px;
  z-index: -1;
}

.new_multiform .react-tabs__tab:last-child:after {
  content: none;
}

th,
tr {
  font-size: 0.9em;
}

td {
  vertical-align: middle;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumb-item+.breadcrumb-item::before {
  margin-top: 4px;
  content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1.2em' width='1.2em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}

/* Custom btn */
.btn-custom,
:not(.btn-check)+.btn-custom:active {
  width: max-content;
  height: max-content;
  padding: 13px 22px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f7e78 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-custom:active,
.btn-custom:focus-visible,
.btn-custom:hover {
  background-color: #1f7e78 !important;
}

.btn-custom-light,
:not(.btn-check)+.btn-custom-light:active {
  width: max-content;
  height: max-content;
  padding: 13px 22px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  background-color: rgba(31, 126, 120, 0.05) !important;
  color: #1f7e78 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-custom-light1 {
  width: max-content;
  height: max-content;
  margin-top: 15px;
  padding: 13px 22px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: #E3ECED !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-custom-light-modal {
  width: max-content;
  height: max-content;
  margin-top: 15px;
  padding: 13px 22px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: #d2d2d2 !important;
  /* background-color: #E3ECED !important; */
  color: black !important;

  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-custom-light-modal1 {
  width: max-content;
  height: max-content;

  padding: 13px 22px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: #f8f8f8 !important;
  color: black !important;
  border: 2px solid #d2d2d2;
  box-shadow: none !important;
  outline: none !important;
}

.btn-custom-light-modal1:active,
.btn-custom-light-modal1:focus-visible,
.btn-custom-light-modal1:hover {
  border: 2px solid #d2d2d2;
}

.btn-custom-light:active,
.btn-custom-light:focus-visible,
.btn-custom-light:hover {
  background-color: rgba(31, 126, 120, 0.05) !important;
  color: #1f7e78 !important;
}

.btn-custom-link {
  color: #1f7e78;
  font-weight: bold;
  cursor: pointer;
  width: max-content;
}

.btn-custom-link1 {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.btn-custom-link svg {
  margin-top: -4px;
}

.custom-tabs .tab {
  cursor: pointer;
  border-bottom-width: 0 !important;
  width: max-content;
  color: #a6a4a4;
}

.custom-tabs .tab.active {
  border-bottom-width: 1px !important;
  border-bottom-color: #1f7e78 !important;
  color: #1f7e78;
}

.numbr {
  font-size: 2em;
  color: #a6a4a4
}

.text-green {
  color: #1f7e78;
}

.ques {
  background-color: #FBFBFB;
  /* height: 200px; */
  border-radius: 8px;
}

.ques-foot {}

.ques-foot .reactions {}

.ques-foot .reactions>* {
  margin-left: 10px;
}

.ques-foot .reactions svg {
  margin-right: 5px;
}

.ques-foot .reactions .forum_valid,
.ques-foot .reactions .forum_invalid,
.ques-foot .reactions .forum_not_sure,
.ques-foot .reactions .forum_toxic {
  display: flex;
  align-items: center;
  background-color: #a6a4a4;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
}

.ques-foot .reactions .forum_valid {
  background-color: #46CCAD;
}

.ques-foot .reactions .forum_invalid {
  background-color: #EC8080;
}

.ques-foot .reactions .forum_not_sure {
  background-color: #FFD167;
}

.ques-foot .reactions .forum_toxic {
  background-color: #9878CE;
}

.top-users {
  background-color: #FBFBFB;
  width: 100%;
  height: 27.8rem;
  border-radius: 8px;
}

.forum_ava img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  float: right;
  margin-right: -5px;
}

.forum_ava.forum_ava_sm img {
  height: 30px;
  width: 30px;
}

.date-image {
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 0px;
  margin-block: 17px;
  left: 20px;
}

.posted_forums54 {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 500;
}

.posted_forums54 span {
  font-weight: 400;
}