.members_card {
  width: 100%;
  margin-bottom: 25px;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  padding: 20px 20px 20px 20px;
}

.members_card img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
}

.members_card p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  text-transform: capitalize;
  letter-spacing: 0.3px;
}
