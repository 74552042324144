.default_container {
  display: flex;
  gap: 10px;
  width: 100%;
  min-height: 125px;
  padding-top: 10px;
  padding-bottom: 22px;
  padding-right: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.default_container div {
  position: relative;
  border: 2px solid #1f7e78;
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin: 0;
  border-radius: 5px;
  /* overflow: hidden; */
}

.default_container .attached img {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.default_container .attached .close_button {
  content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='red' stroke-width='0' viewBox='0 0 512 512' height='1.2em' width='1.5em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm52.7 283.3L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3l52.7-52.7-52.7-52.7c-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3 6.2-6.2 16.4-6.2 22.6 0l52.7 52.7 52.7-52.7c6.2-6.2 16.4-6.2 22.6 0 6.2 6.2 6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0z'%3E%3C/path%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -12px;
  margin-right: -12px;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add_icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_icon svg {
  position: relative;
  z-index: 1;
}

.add_icon .fileUploader {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.pdfAttached::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  border: none;
}

.pdfAttached::-webkit-scrollbar-thumb {
  background-color: #D9E3F7;
}

.pdfAttached::-webkit-scrollbar-thumb:hover {
  background-color: #F0F0F0;
  cursor: pointer;
}

.file-name {
  width: 100%;
  overflow: hidden;
  font-size: 15px;
  white-space: nowrap;
  margin-top: 5px;
  text-overflow: ellipsis;
}