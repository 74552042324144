.dashboard_title {
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;

  letter-spacing: 0.4px;
}

.dashboard_title .icon {
  margin-right: 5px;
  font-size: 35px;
  position: relative;
  top: -2px;
  color: black;
  cursor: pointer;
}

.dashboard_title .float_text5 {
  float: right;
  color: #1f7e78;
  font-weight: 500;
  letter-spacing: 0.1px;
  position: relative;
  top: 4px;
  cursor: pointer;
}

.dashboard_title img {
  object-fit: cover;
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

.dashboard_title .patient_name2 {
  font-weight: 500;
  font-size: 14px;
  margin-left: 7px;
}

.dashboard_title .patient_lifestyle2 {
  font-weight: 500;
  font-size: 14px;
  margin-left: 7px;
  color: #1f7e78;
  /* text-transform: lowercase; */
}

.dashboard_title .patient_lifestyle3 {
  font-weight: 500;
  margin-left: 7px;
  color: #1f7e78;
}

.dashboard_box {
  height: 150px;
  width: 100%;
  background: #fffefe;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  padding: 0px 30px 0px 30px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.dashboard_box:hover {
  background: #1f7e78;
}

.dashboard_box img {
  height: 50px;
  vertical-align: text-bottom;
}

.dashboard_box p {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 0.4px;
  width: 100%;
  color: black !important;
}

.dashboard_box:hover p {
  color: #fff !important;
}

.reminder {
  width: 100%;
  background: linear-gradient(99.25deg, #ff5964 8.91%, #e33540 87.91%);
  box-shadow: 0px 4px 20px #ffaab0;
  border-radius: 11px;
  padding: 20px 25px 30px 25px;
}

.reminder_title {
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
  color: white;
}

.reminder_title .icon {
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.reminder_txt .icon {
  color: white;
  margin-right: 7px;
  font-size: 20px;
}

.reminder_txt p {
  color: white;
  margin-top: -2px;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
  width: 90%;
}

.quick_update {
  width: 100%;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 11px;
  padding: 20px 23px 10px 23px;
}

.quick_update .icon {
  margin-right: 5px;
  font-size: 28px;
}

.quick_update p {
  color: black;
  margin-top: 2px;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}

.link_text {
  text-decoration: none;
}


.reminder_inputf5 {
  height: 55px;
  width: 94%;
  margin-left: 3%;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: black;
 padding-right: 80px;
  margin-bottom: 15px;
  background-color: #fff;
}

.reminder_gbtn{
  width: 100%;position: relative;
}


.reminder_gbtn .boxmod{
  position: absolute;right: 35px;top: 12px;
}

.reminder_gbtn .delete{
  position: relative;
margin-left: 10px;color: red;cursor: pointer;top: 3px;
accent-color: #1F7E78;height: 16px;width: 16px;
}

.reminder_gbtn .add{
 color: #1F7E78;font-size: 19px;cursor: pointer;
}

.reminder_addnew45{
  background: #1f7e78;
  border-radius: 10px;
  height: 55px;
  border: 0;
  font-size: 17px;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  font-weight: 500;
  width: 36%;
}


.reminder_outerdiv87 {
  max-height: 233px;
  overflow-y: scroll;
  position: relative;
}

.reminder_outerdiv87::-webkit-scrollbar {
  width: 8px;
  background: #E33540;
}
/* Optional: show position indicator in red */
.reminder_outerdiv87::-webkit-scrollbar-thumb {
  background: #E33540;
}
.reminder_outerdiv87:hover::-webkit-scrollbar-thumb {
  background: #FF5964;
  border-radius: 7px;
  
}


