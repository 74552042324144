.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 27px;
  top: -1px;
  margin-left: 6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  top: 2px;
  right: 4px;
}

input:checked + .slider {
  background-color: #4daa28;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4daa28;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.booking_accept {
  margin-right: 10px;
}

.view_profile76 {
  background-color: #1f7e78;
  height: 35px;
  letter-spacing: 0.4px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 30px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
}
