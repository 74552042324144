.notification.sidebarNotification {
    height: 100%;
    width: 420px;
    position: fixed;
    z-index: 10;
    top: 0;
    right: -420px;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 0;
}

.notification.backdrop {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    background-color: #0009;
    overflow-x: hidden;
    /* transition: 0.3s; */
    left: 0;
    top: 0;
}

.notification.sidebarNotification a {
    padding: 8px 8px 8px 15px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.notification.sidebarNotification a:hover {
    color: #f1f1f1;
}

.notification.sidebarNotification .closebtn {
    /* position: absolute;
    top: 0;
    left: 0; */
    margin-left: 15px;
    background: #1f7e78;
    border-radius: 5px;
    height: 37px;
    width: 51px;
    border: none;
    color: #fff;
    margin-top: 8px;
}

.notification.sidebarNotification .closebtn .iconback {
    font-size: 30px;
}

.notificationwraper {
    padding: 8px 8px 8px 15px;
}

.notificationwraper .box_wrapper {
    box-shadow: 0px 0px 6px -2px #000;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin-bottom: 14px;
    cursor: pointer;
}

.notificationwraper .box_wrapper:hover {
    background-color: #1f7e78;
    color: #fff;
}

.notificationwraper .box_content {
    font-size: 13px;
    font-weight: bold;
}

.notificationwraper .box_status {
    font-size: 13px;
    background: #1f7e78;
    position: absolute;
    top: 9px;
    right: 5px;
    color: #fff;
    padding: 0px 10px;
    border-radius: 10px;
}

.notificationwraper .box_wrapper:hover .box_status {
    background: #fff;
    color: #000;
}

.font_weight_normal {
    font-weight: normal !important;
}