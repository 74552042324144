.appointment_table thead {
  background-color: #2c968f;
  box-shadow: 0px 4px 20px rgba(57, 94, 201, 0.5);
  border-bottom: 1px solid #2c968f !important;
}



.appointment_table {
  margin-bottom: 35px;
}

.appointment_table1 thead tr th {
  color: #7a7a7a;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0.2px;
}

.appointment_table thead tr th {
  color: white;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0.2px;
}

.appointment_table1 tbody {
  border-bottom-width: 0px !important;
  position: relative;
  top: 10px;
}

.no-border {
  border-bottom-width: 0px !important;
}

.appointment_table tbody {
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 12px !important;
  border-top: 0 !important;
  position: relative;
  top: 10px;
}

.appointment_table1 tbody tr td {
  color: black;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  padding-bottom: 25px;
}

.appointment_table tbody tr td {
  color: black;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 30px;
  padding-bottom: 25px;
}

.appointment_table tbody:before {
  /* This doesn't work because of border-collapse */
  line-height: 1em;
  content: ".";
  color: white;
  /* bacground color */
  display: block;
}

.booking_request_table tbody::after {
  /* This doesn't work because of border-collapse */
  line-height: 1em;
  content: ".";
  color: white;
  /* bacground color */
  display: block;
}

.color-disabled {
  background-color: #ececec;
}

.disable-blur-button {
  filter: blur(2px);
}

.booking_request_table tbody tr td {
  vertical-align: middle;
  padding: 8px 10px 25px;
}

.start_call {
  background-color: #1f7e78;
  height: 35px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 30px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
  letter-spacing: .4px;
}

.start_call1 {
  background-color: #1f7e78;
  height: 35px;
  width: 100px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 30px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
  letter-spacing: .4px;
}

.start_call span {
  position: relative;
  top: -2px;
}

.appointment_cancel {
  background-color: #d81010;
  height: 35px;
  letter-spacing: .4px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 30px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
}

.appointment_cancel span {
  position: relative;
  top: -2px;
}

.today4_bottomhr {
  border-bottom: 1px solid #CBCBCB;
  width: 98%;
  margin-left: 2%;
}


.upcoming_scroll_div {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.upcoming_scroll_div::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Optional: show position indicator in red */
.upcoming_scroll_div::-webkit-scrollbar-thumb {
  background: white;
}

.upcoming_scroll_div:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 7px;
}

.link_profile {
  cursor: pointer;
  color: #2c968f;
}