.group_profile {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -7px;
}

.jhbdh {
  overflow-y: scroll;
  height: 100px;
  position: absolute;
}

.fixed_header2 {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header2 tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 300px;
}

.fixed_header2 tbody::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Optional: show position indicator in red */
.fixed_header2 tbody::-webkit-scrollbar-thumb {
  background: white;
}

.fixed_header2 tbody:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 7px;
}
.fixed_header2 thead tr {
  display: block;
}

.fixed_header2 th {
  width: 180px;
}

.fixed_header2 td {
  min-width: 106px;
}

.fixed_header2 tbody td {
  padding-top: 20px;
  padding-bottom: 14px !important;
}


.tooltip-td {
  position: relative;
}

.tooltip-td .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #459590;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 60%;
  left: 60%;
  margin-left: -60px;
}

.tooltip-td .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #459590 transparent;
}

.tooltip-td:hover .tooltiptext {
  visibility: visible;
}