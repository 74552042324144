.profile_header {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  height: 70px;
  width: 100%;
  border-radius: 4px;
  padding: 10px 10px 0px 20px;
}

.profile_title {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  margin-top: 12px;
  letter-spacing: 0.2px;
  margin-left: -2%;
}

.message_profile9 {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.active_dummyimage1 {
  height: 130px;
  width: 130px;
  border-radius: 5px;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

.upload_icon {
  font-size: 35px;
  color: #1f7e78;
  box-shadow: 0px 4px 20px #57b7b1;
  border-radius: 50%;
  margin-top: 35px;
  margin-left: -28px;
}

.css-1s2u09g-control,
.css-13cymwt-control {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border: 2px solid #D9D9D9 !important;
  border-radius: 5px !important;
  background-color: rgba(217, 217, 217, 0.15) !important;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  /* text-transform: capitalize; */
  margin-bottom: 35px !important;
  color: #d2d2d2;
}

.css-1pahdxg-control,
.css-t3ipsp-control {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  margin-bottom: 35px;
  outline: none !important;
  color: #d2d2d2 !important;
}

.css-1rhbuit-multiValue {
  background-color: #1f7e78 !important;
  color: white !important;
  border-radius: 4px !important;
  padding: 5px 8px !important;
}

.css-12jo7m5 {
  color: white !important;
  font-size: 14px !important;
}

.css-1pahdxg-control:hover {
  border-color: #1f7e78 !important;
}

.profile_circleinput {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  margin-left: 7%;
  margin-top: -40px;
  z-index: 9999;
  background-color: red;
}

.profile_circleimask {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-left: 8.7%;
  margin-top: -40px;
}

.profile_circleicon {
  font-size: 35px;
  color: #1f7e78;
  background-color: white;
  box-shadow: 0px 4px 20px #57b7b1;
  border-radius: 50%;
}

.profile_iconmain8 {
  height: 130px;
}

.profile_iconmain99 {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.add-item-input {
  border: none;
  width: 98%;
  outline: none;
  height: 20px;
  margin-top: 15px;
}

.react_stars {
  margin-top: -60px;
  margin-right: 40px;
}

.whole_label1 {
  text-transform: capitalize;
  font-weight: 600;
  color: rgb(65, 62, 62);
  font-size: 15px;
  margin-bottom: 2px;
  letter-spacing: 0.3px;
  margin-left: 23px;
  margin-bottom: 10px;
}

.rating_back89 {
  background-color: #f1f1f1 !important;
  border-radius: 30px;
  padding: 0px 15px 5px 15px;
}

.ratinglist_card1 {
  width: 99%;
  background: #FFFEFE;
  box-shadow: 0px 4px 20px #CCC9CA;
  border-radius: 12px;
  padding: 20px 20px 20px 40px;

}

.ratinglist_card1 img {
  padding: 3px;
  /* font-size: 25px; */
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 100px;
  color: black;
  cursor: pointer;
  margin-top: 5px;
}

.rating_parag1 {
  font-size: 16px;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
}

.rating_parag2 {
  font-size: 14px;
  color: #565656;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: -10px;
}

.myrating_sideline {
  border-right: 1px solid #D1D1D1;
}

.myratinglist_star {
  margin-top: 20px;
}

.myratinglist_star .yellow {
  color: #F0CF1E;
  margin-left: 7px;
  font-size: 20px;
}

.myratinglist_star .grey {
  color: #E2E2E2;
  margin-left: 7px;
  font-size: 20px;
}

.profile_date input {
  height: 55px;
  width: 100%;
  border: 2px solid #d2d2d2 !important;
  border-radius: 5px;
  padding-left: 50px !important;
  /* padding-right: 25px; */
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: #9f9f9f !important;
  margin-bottom: 20px;
  background-color: rgb(217 217 217 / 15%) !important;
}