.header_policy{
  background-color: #1f7e78;
  color: #fff;
  padding: 15px 10px;
  box-shadow: 0px 0px 26px -5px #396461;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.header_text{
  font-size: 25px;
  margin: 0;
  text-align: center;
}

.arrow_poly{
  font-size: 29px;
  cursor: pointer;
}

.body_policy{
  padding-top: 100px;
}

.body_policy h2, .body_policy h3{
  font-size: 22px;
}

.body_policy a{
  text-decoration: none;
  color: #1f7e78;
}

.body_policy ul li, .body_policy ol li{
  font-size: 15px;
}

.body_policy p{
  font-size: 15px;
  text-align: justify;
}

.contactUslist{
  list-style: none;
  display: flex;
  padding-left: 0px;
}
.contactUslist li:first-child{
  font-weight: bold;
  margin-right: 10px;
}

.detailsContacts{
  margin: auto;
  width: 600px;
  padding: 25px;
  border: 3px solid #1f7e78;
  border-radius: 10px;
  padding-bottom: 10px;
}

.policy_usage h2, .policy_usage h3{
  font-size: 17px;
}


.templete_card {
  background: white;
  width: 83%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  position: relative;
  top: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 45px 100px 45px;
  overflow: hidden;
  border-radius: 4px;
}
.templete_polygon {
  clip-path: polygon(0 0, 100% 0, 100% 30%, 9% 60%);
  background-color: #1f7e78;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
}
.templte_title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.4px;
  font-family: Arial, Helvetica, sans-serif;
}
.templte_desc {
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.4px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.templte_img {
  height: auto;
  margin-left: -67px;
  width: auto;
}

@media only screen and (max-width: 500px) {
  .templte_title {
    font-size: 16px !important;
  }
  .templte_desc {
    font-size: 15px;
    line-height: 25px;
  }

  .templete_card {
    background: white;
    width: 70%;
    padding: 20px 35px 60px 35px;
  }
  .templte_img {
    width: 100%;
    margin-left: -35px;
  }
}