.checknum {
    font-size: 1.5em;
}
.checknum {
    outline-color: #1f7e78;
}
.form-check-input.checknum:checked {
    background-color: #1f7e78;
    border-color: #1f7e78;
}

  .top-users {
    height: auto;
  }
  
  .scroller-custom::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background: rgba(31, 126, 120, 0.502);
    border-radius: 5px;
  }
  
  /* Optional: show position indicator in red */
  .scroller-custom::-webkit-scrollbar-thumb {
    background: #1f7e78;
    border-radius: 5px;
  }
  
  /* .scroller-custom:hover::-webkit-scrollbar-thumb {
    background: #1f7e78;
  } */

  .richtextCustom.rm .ql-container.ql-snow {
    border: none !important;
  }

  .richtextCustom.rm .ql-editor {
    padding: 0 !important;
  }

  #transformNone {
    text-transform: none !important;
  }

  .text_bolderror{
    font-size: 19px; 
    color: rgb(143, 143, 143); 
    font-weight: 700; 
    letter-spacing: 0.4px;
     top: 0px; 
     position: relative;
  }