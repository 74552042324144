.library_icon {
  position: relative;
  top: -1px;
  margin-right: 10px;
  font-size: 20px;
}

.mylibrary_wid {
  width: 85%;
  margin-left: 0px;
}

.library_box {
  height: 130px;
  width: 100%;
  background: #fffefe;
  box-shadow: 0px 4px 20px #ccc9ca;
  border-radius: 12px;
  padding: 20px 40px 20px 30px;
  display: block;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.library_box:hover {
  cursor: pointer;
}

.library_box img {
  height: 50px;
  float: right;
}

.library_box p {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  letter-spacing: 0.4px;
  width: 100%;
  color: black !important;
  margin-top: 60px;
}
