.view_profile12 {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 20px 20px 20px 20px;
}

.drprofile_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.drprofile_title {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
  margin-top: 12px;
  letter-spacing: 0.2px;
  margin-left: 12px;
}

.drprofile_hr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.drprofile_text1 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: black;
}

.drprofile_text2 {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  color: #0c0c0c;
}

.drprofile_header {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    height: 70px;
    width: 100% !important;
    border-radius: 4px;
    padding: 10px 10px 0px 20px;
    margin-top: -10px;
  }

  .drprofile_title {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: black;
    margin-top: 12px;
    letter-spacing: 0.2px;
    margin-left: 2%;
  }
.small_letter2{
  text-transform: lowercase !important;
}


.dr_management_scroll {
  height: 75.5vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dr_management_scroll::-webkit-scrollbar {
  width: 8px;
  background: white;
}

/* Optional: show position indicator in red */
.dr_management_scroll::-webkit-scrollbar-thumb {
  background: white;
}

.dr_management_scroll:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 7px;
}