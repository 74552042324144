.update_recipe {
  display: grid;
  place-content: center;
  background-color: #1f7e78;
  height: 35px;
  width: 100px;
  letter-spacing: 0.4px;
  border-radius: 30px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
  text-decoration: none;
  text-align: center;
}

.update_recipe:hover {
  color: white;
}

.delete_recipe {
  background-color: #d81010;
  height: 35px;
  letter-spacing: 0.4px;
  padding-left: 30px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  border: 0;
  text-transform: capitalize;
}

.recipe_image_ {
  height: 45px;
  width: 45px;
  border-radius: 10%;
  object-fit: cover;
  /* margin-top: -7px; */
}

.delete_recipe span {
  position: relative;
  top: -2px;
}

.recipe_btnsave {
  background: #1f7e78;
  border-radius: 10px;
  height: 65px;
  border: 0;
  font-size: 19px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  letter-spacing: 0.4px;
  margin-top: 30px;
}

.uploader-input_ {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  right: 0;
  margin-right: -8px;
  margin-top: -40px;
}

.uploader-input_new {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  right: 6.4%;
  margin-right: -8px;
  margin-top: -40px;
}

.recipe_select .css-1s2u09g-control {
  margin-bottom: 0 !important;
}

.add-recipe label {
  font-size: 16px;
  font-weight: normal;
}

.add-recipe .css-26l3qy-menu {
  font-size: 16px;
  font-weight: normal;
}

.add-recipe .css-1s2u09g-control {
  /* padding-top: 13px;
  padding-bottom: 13px; */
  width: 100%;
  border: 2px solid #d2d2d2 !important;
  /* border-radius: 10px !important; */
  padding-left: 25px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  color: #d2d2d2;
  margin-bottom: 20px !important;
}

.add-recipe .css-1pahdxg-control {
  font-size: 1rem;
}

.add-recipe .tags .css-12jo7m5 {
  display: grid;
  place-items: center;
  /* background-color: white; */
  color: black !important;
  padding: 0 !important;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.view_recipe_div {
  /* box-shadow: 0px 4px 20px #ccc9ca; */
  border-radius: 12px;
  width: 100%;
  padding: 23px;
  /* min-height: 430px; */
}

.view_recipe_div img {
  height: 257px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.viewrecipetitle {
  font-size: 26px;
  color: black;
  font-weight: 700;

  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* text-transform: lowercase; */
}

/* .viewrecipetitle:first-letter {
  text-transform: uppercase !important;
} */

.recipe_meter {
  border: 2.3px solid #1f7e78 !important;
  text-align: center;
  border-radius: 10px;
  padding: 14px 10px 2px 10px;
  line-height: 7px;
}

.recipe_meter .meter1 {
  font-size: 14px;
  color: black;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.recipe_meter .meter2 {
  font-size: 16px;
  color: black;
  font-weight: 700;
}

.recipe_meter .meter3 {
  font-size: 14px;
  color: black;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.viewrecipe_server {
  font-size: 16px;
  color: black;
  letter-spacing: 0.4px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 0;
}

.verticalLine {
  height: 300px;
  border-right: 1px solid #2e3031;
  position: absolute;
  right: 50%;
}

.viewrecipe_server span {
  color: #1f7e78;
}

.viewbadge_bg1 {
  height: 40px !important;
  width: 40px !important;
  margin-right: 3px;

}

.viewbadge_bg2 {
  height: 60px !important;
  width: 60px !important;
  margin-right: 3px;

}



.viewrecipe_ul {
  list-style-type: circle;
  font-size: 15px;
  letter-spacing: .3px;
}

.viewrecipe_editbtn7 {
  background: #1f7e78;
  border-radius: 10px;
  height: 45px;
  border: 0;
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 25px;
  padding-right: 25px;
  letter-spacing: 0.4px;
  float: right;
  margin-top: 3px;
}

.text-editor-receipe .ql-container {
  height: 250px;
  max-height: 250px;
  /* overflow: auto; */
}

.pointer {
  cursor: pointer;
}

.bg-green {
  background-color: #1f7e78;
}