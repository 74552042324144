.login_image {
  height: 100vh;
  width: auto;
  margin-left: -2%;
}

.login_input {
  height: 60px;
  width: 80%;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 65px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  /* text-transform: capitalize; */
  margin-bottom: 20px;
}

.login_input::placeholder {
  color: #9f9f9f;
}

.login_arr {
  position: absolute;
  top: 0;
  right: 0;
  height: 90px;
}

.logo_login {
  margin-top: 55px;
  height: 130px;
}

.continue_login {
  /* font-size: 24px;

  color: black;
  font-weight: 700;
  letter-spacing: 0.3px; */
}

.forgot_password {
  /* text-align: left; */
  width: 80%;
  margin-right: 10%;
  font-size: 17px;
  /* text-transform: capitalize; */
  color: black;
  /* font-weight: 500; */
  letter-spacing: 0.3px;
  /* margin-top: -6px;
  margin-bottom: -10px; */
}

.forgot_password input {
  height: 20px;
  width: 20px;
  position: relative;
  top: 3px;
  margin-right: 3px;
  accent-color: #1f7e78;
}

.forgot_password span {
  color: black;
  float: right;
}

.login_btnlogin {
  background: #1f7e78;
  border-radius: 10px;
  height: 60px;
  border: 0;
  font-size: 19px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  width: 80%;
  letter-spacing: 0.4px;
}

.login_btngoogle {
  border-radius: 10px;
  height: 60px;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  font-size: 17px;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
  width: 80%;
  letter-spacing: 0.4px;
}

.login_btngoogle img {
  height: 27px;
}

.password_eye {
  position: absolute;
  margin-left: -60px;
  margin-top: 7px;
  color: #9f9f9f;
  height: 44px;
}

.connect_to_zoom {
  background: #1f7e78;
  border-radius: 10px;
  height: 60px;
  border: 0;
  font-size: 19px;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  width: 60%;
  letter-spacing: 0.4px;
  /* margin-left: -200px;  */
}

.connect_to_zoom_p {
  margin-top: 1px;
  text-align: left;
  /* margin-left: 10px; */
  font-weight: 500;
  /* margin-bottom: -6px; */
}