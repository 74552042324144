@media only screen and (max-width: 500px) {
  /* start navbar  section */

  .mnav_wid30 {
    display: inline-block;
    width: 105px;
  }

  .mnav_wid70 {
    display: inline-block;
    width: 61%;
    padding-left: 0px !important;
  }

  .fulllogo {
    width: 100% !important;
    height: 65px !important;
    padding: 11px !important;
    object-fit: contain !important;
    border: 0px !important;
    position: relative !important;
    left: -24%;
  }

  .header_marbot {
    margin-bottom: -11px;
  }

  .head_bellmob8 {
    right: -15px;
  }

  .bell_iconmob8 {
    position: absolute !important;
    right: -15px !important;
    top: -48px;
  }

  .header_namepro90 {
    position: absolute !important;
    left: 12% !important;
    top: -10px !important;
  }

  .header_fullwid09 {
    width: 100% !important;
    display: block !important;
  }

  .prob {
    background-color: white !important;
  }

  .navgrey {
    width: 100%;
    height: auto !important;
    border-radius: 0px !important;
    padding: 0px !important;
  }

  .mpush_top {
    position: relative !important;
  }

  .bell_icon {
    padding: 9px !important;
    font-size: 31px !important;
    border-radius: 6px !important;
    margin-right: 10px !important;
  }

  .logout_icon {
    padding: 9px 7px 9px 7px !important;
    border-radius: 6px !important;
    height: 31px !important;
  }

  .search_box1 {
    height: 40px !important;
    border-radius: 2px !important;

    padding: 3px 19px 6px 20px !important;
  }

  .search_box1 input {
    height: 35px !important;
    border: 0 !important;
    font-size: 14px !important;
  }

  .search_box1 .boxicon {
    font-size: 16px !important;
    margin-top: 10px !important;
    margin-right: 8px !important;
  }

  .whole_label1 {
    margin-top: 70px !important;
  }

  .react_stars {
    float: left !important;
  }

  .profile_img {
    height: 85px !important;
    width: 85px !important;
    margin-top: -5px !important;
    margin-left: -20px !important;
  }

  .image_icon {
    padding: 2px;
    width: 35px !important;
    height: 35px !important;
    margin-right: 10px !important;
    margin-top: -2px !important;
  }

  .profile_name {
    font-size: 15px !important;
    margin-top: 21px !important;
    margin-left: -25px !important;
  }

  .profile_desc_wrapper {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px !important;
    margin-left: -25px !important;
    margin-top: -4px !important;
  }

  /* 
  .profile_desc {
    font-size: 12px !important;
    margin-top: -37px !important;
    margin-left: -14px !important;
  } */

  /* end navbar  section */

  /* start sidebar  section */

  .sidebar {
    height: auto !important;
    width: 100%;
    margin-left: 0%;
    margin-bottom: 30px;
    position: relative;
    top: 0% !important;
    position: relative !important;
    width: 100% !important;
    top: 20%;
  }

  .sidebar p {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

  .sidebar p .icon {
    font-size: 20px !important;
  }

  /* end sidebar  section */

  /* start dasboard  section */
  .reminder_txt .icon {
    margin-right: 4px !important;
    font-size: 18px !important;
  }

  .reminder_txt p {
    font-size: 14px !important;
    letter-spacing: 0.1px !important;
  }

  .quick_update .icon {
    font-size: 24px !important;
  }

  .quick_update p {
    color: black !important;
    margin-top: 1px;
    font-size: 14px !important;
    letter-spacing: 0.1px;
  }

  .quick_update {
    padding: 15px 23px 3px 23px !important;
  }

  .dashboard_title {
    font-size: 15px !important;
    font-weight: 500 !important;
    letter-spacing: 0.3px !important;
  }

  .dashboard_title .icon {
    margin-right: 4px;
    font-size: 28px;
    top: -2px;
  }

  .dashboard_title .float_text5 {
    font-size: 14px;
    top: 2px;
  }

  /* end dasboard  section */

  /* start appointment  section */

  .table_resouter {
    overflow-x: auto;
  }

  .active_listscroll {
    border-collapse: collapse;
    border-spacing: 0;
    width: 440px;
  }

  .clock_icon {
    height: 20px !important;
    top: 15px !important;
  }

  .table_resinner {
    border-collapse: collapse;
    border-spacing: 0;
    width: 800px;
  }

  .table_resinner2 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 1000px;
  }

  .table_resinner3 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 600px;
  }

  .table_resinner4 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 550px;
  }

  .table_resinner45 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 450px;
  }

  .wid_prodiledet {
    width: 250px;
    display: inline-block !important;
  }

  /* end appointment  section */

  /* start message section  */

  .mchat_wid1 {
    display: inline-block;
    width: 60px;
    margin-left: 5%;
  }

  .mchat_wid2 {
    display: inline-block;
    max-width: 71%;
  }

  .message_title span {
    color: #4daa28;
    display: block;
    float: right;
    position: relative !important;
    top: -18px !important;
  }

  .mlist_wid1 {
    display: inline-block;
    width: 55px !important;
  }

  .mlist_wid2 {
    display: inline-block;
    width: 79%;
  }

  .message_sidebar {
    width: 100%;
    padding: 22px 0px 22px 22px;
  }

  .side_image8 {
    position: relative !important;
    right: 6px !important;
  }

  .mctxt_wid1 {
    display: inline-block;
    width: 55px;
  }

  .mctxt_wid2 {
    display: inline-block;
    width: 80%;
  }

  .chat_image6 {
    height: 38px !important;
    width: 38px !important;
  }

  .chat_para54 {
    background-color: #1d7771;
    padding: 15px 24px 20px 24px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0.2px;
    border-radius: 0px 30px 30px 30px !important;
  }

  .chat_parasec {
    background-color: #e7e7e7;
    padding: 15px 24px 20px 24px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .chat_inputsection {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

  .chat_inputsection input {
    height: 49px !important;
    font-size: 13px !important;
    font-weight: 500;
    padding-left: 21px !important;
    padding-right: 21px !important;
    margin-right: 10px !important;
  }

  .chat_inputsection img {
    padding: 12px !important;
    height: 50px !important;
    position: relative;
    top: 0px;
  }

  /* end message section  */

  /* start active program section */

  .upcoming_scroll_div {
    overflow-x: scroll !important;
  }

  .actsearch_box1 {
    height: 45px !important;
    padding: 3px 19px 6px 20px !important;
    margin-left: 0% !important;
  }

  .actsearch_box1 input {
    height: 38px !important;
  }

  .actsearch_box1 .boxicon {
    margin-top: 11px !important;
  }

  .create_newprogram {
    height: 45px !important;
    font-size: 14px !important;
    float: none !important;
    width: 100%;
    margin-top: 10px;
  }

  .create_bottomhr {
    margin-top: 20px !important;
    margin-bottom: 0px;
  }

  .react-tabs__tab-list {
    display: inline-block !important;
  }

  .react-tabs__tab {
    display: inline;
    margin-right: 0% !important;
    width: 50% !important;
  }

  .active_msearch6 {
    margin-bottom: 25px !important;
    margin-left: 0% !important;
  }

  .active_dummyimage {
    height: 250px !important;
    margin-bottom: 10px;
  }

  .uploader-input {
    height: 45px !important;
    width: 45px !important;
    margin-left: 74% !important;
  }

  .uploader-mask {
    height: 45px !important;
    width: 45px !important;
    margin-left: 89.2% !important;
    margin-bottom: 20px !important;
  }

  .upload-icon {
    font-size: 45px !important;
  }

  .description_inputf {
    height: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 15px !important;
    border: 3px solid #d9d9d9 !important;
  }

  .create_newplan {
    margin-top: -170px !important;
    width: 83%;
    margin-left: 0px !important;
    margin-bottom: 100px !important;
    position: absolute;
  }

  .caremob_hr {
    margin-top: 75px !important;
  }

  .description_descpf {
    height: auto !important;
    padding-left: 20px !important;
  }

  .update_btnsave {
    margin-left: 0% !important;
  }

  .description_btnsave {
    height: 50px !important;
    font-size: 16px !important;
    margin-bottom: 15px;
  }

  .css-1s2u09g-control {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .css-1pahdxg-control {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .description_savemodule {
    height: 50px !important;
    font-size: 16px !important;
    margin-bottom: 15px;
    width: 100% !important;
  }

  .description_addanother {
    height: 50px !important;
    font-size: 16px !important;

    width: 100% !important;
  }

  .description_createsess {
    height: 50px !important;
    font-size: 16px !important;

    width: 100% !important;
  }

  .forum_wid1 {
    display: inline-block;
    width: 60px;
  }

  .forum_wid2 {
    display: inline-block;
    width: 66%;
  }

  .forum_wid3 {
    display: inline-block;
    width: 90%;
  }

  .forums_textdiv {
    width: 100%;
    padding: 15px 0px 20px 25px !important;
  }

  .forums_sendmess {
    text-align: left !important;
  }

  .forums_iconspan {
    margin-top: -10px !important;
    float: left !important;
  }

  .actlist_wid1 {
    display: inline-block;
    width: 90px;
  }

  .actlist_wid2 {
    display: inline-block;
    width: 60%;
  }

  .memberside_list {
    margin-bottom: 20px !important;
  }

  .recipe_image_ {
    margin-bottom: 17px !important;
  }

  /* end active program section */

  /* start login section */
  .zoom_group {
    display: block !important;
  }

  .connect_to_zoom {
    height: 50px !important;
    font-size: 15px !important;
    width: 90% !important;
    margin-top: 7px !important;
    margin-left: 1% !important;
  }

  .connect_to_zoom_p {
    margin-top: 10px !important;
    text-align: none !important;
    margin-left: 10px;
    font-weight: 500;
    margin-left: 16px !important;
    margin-bottom: 6px !important;
    width: 90% !important;
  }

  .login_image,
  .login_arr {
    display: none;
  }

  .signUpMsg12 {
    margin-left: 7% !important;
  }

  .logo_login {
    height: auto !important;
    width: 100%;
  }

  .login_input {
    height: 50px !important;
    width: 90% !important;
  }

  .continue_login {
    font-size: 18px !important;
  }

  .forgot_password {
    margin-left: 6% !important;
    font-size: 13px !important;
    text-transform: capitalize;
    color: black;
    /* font-weight: 500;
    letter-spacing: 0.3px;
    margin-top: -6px;
    width: 88% !important; */
  }

  .forgot_password input {
    height: 15px !important;
    width: 15px !important;
  }

  .forgot_password span {
    float: right !important;
    margin-top: 0px !important;
    margin-bottom: 10px;
  }

  .login_btnlogin {
    height: 50px !important;
    font-size: 15px !important;
    width: 90% !important;
    margin-top: 7px !important;
  }

  .login_btngoogle {
    height: 50px !important;
    font-size: 15px !important;
    width: 90% !important;
    margin-bottom: 70px;
  }

  .login_btngoogle img {
    height: 18px !important;
    position: relative;
    top: -2px;
  }

  .password_eye {
    /* margin-left: -50px !important; */
    margin-top: 2px !important;
    /* height: 14px !important; */
  }

  .profile_header {
    padding: 10px 10px 0px 0px !important;
  }

  .profile_div {
    position: relative !important;
    margin-top: 5px !important;
  }

  /* end login section */

  /* start blog section */
  .post_container {
    width: 128% !important;
    margin-left: -14%;
  }

  .post_image {
    height: 200px !important;
  }

  .post_heading {
    font-size: 17px !important;
    margin-top: 45px !important;
  }

  /* end blog  section */

  /* start all patient  */

  .allpatient_profile {
    overflow-x: scroll !important;
  }

  .allwebwid30 {
    width: 100% !important;
    margin-bottom: 25px !important;
  }

  .givenameinput {
    width: 94% !important;
  }

  .allwebwid70 {
    width: 100% !important;
  }

  .addpatient_input {
    height: 50px !important;
    padding: 3px 15px 5px 5px !important;
    margin-bottom: 15px !important;
  }

  .addpatient_input select {
    height: 39px !important;
  }

  .addpatient_input input {
    height: 39px !important;
  }

  .affirmations {
    padding: 22px 22px 100px 22px !important;
    margin-bottom: 30px;
  }

  .modal_text_body {
    font-size: 16px !important;
    line-height: 30px;
  }

  .checklist_mob {
    margin-top: 120px !important;
  }

  .affir_tile {
    font-size: 16px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    margin-bottom: 30px;
  }

  .affir_tile button {
    height: 35px !important;
    font-size: 14px !important;
  }

  .affir_checkbox {
    font-size: 14px !important;
  }

  .affir_checkbox input {
    height: 14px !important;
    width: 14px !important;
  }

  .apatient_reflect {
    padding: 30px 30px 30px 30px !important;
  }

  .apatient_reflect p {
    font-size: 14px !important;
  }

  .apatient_reflect p button {
    height: 35px !important;
    font-size: 14px !important;
    float: none !important;
    display: block;
    width: 30% !important;
    top: 5px !important;
  }

  .edit_takecare1 {
    font-size: 15px !important;
  }

  .editaffirmationdiv p {
    font-size: 16px !important;
  }

  .editaffirmationdiv textarea {
    font-size: 15px;
  }

  .edit_affirbtn {
    height: 50px !important;
    font-size: 16px !important;
    width: 100% !important;
  }

  .editaffirmationdiv input {
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: 50px !important;
    font-size: 16px !important;
  }

  .patient_row1 {
    width: 100% !important;
  }

  .allpatient_name12 {
    text-align: left !important;
    margin-top: 10px !important;
    margin-left: 10px;
  }

  .allpatient_search {
    height: 50px !important;
    width: 108% !important;
  }

  .allpatient_search input {
    height: 45px !important;

    font-size: 14px !important;
  }

  .allpatient_search .boxicon {
    font-size: 16px !important;
    margin-top: 15px !important;
    margin-right: 8px;
  }

  .goals_symtoms {
    width: 100% !important;
    margin-bottom: 25px;
  }

  .patient_btndiv98 {
    display: flex;
    flex-direction: column !important;
    margin-top: 0px !important;
  }

  .patient_textimp {
    height: 50px !important;
    margin-bottom: 10px;
    width: 100% !important;

    font-size: 15px !important;
  }

  .mheight_fix {
    height: auto !important;
  }

  .currread_imgt1 {
    width: 100% !important;
  }

  .man_graph {
    margin-left: 14%;
  }

  .menbodyimg {
    height: 100px !important;
    margin-right: 2%;
  }

  .riskprofilehistory {
    height: 100px !important;
  }

  .addpatient_margin8 {
    margin-top: 0px !important;
    margin-bottom: 20px;
  }

  /* end all patient  */

  /* start group section  */

  .message_title button {
    height: 35px !important;
    font-size: 11px !important;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -5% !important;
    position: relative;
    top: -22px !important;
  }

  .message_title .textcover1 {
    width: 55%;
    font-weight: 500 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .care_tem9 {
    font-size: 15px !important;
  }

  .nutrishift_overview {
    margin-top: 35px;
  }

  .infographicimg,
  .riskprofilehistory {
    margin-left: 30% !important;
    margin-top: 20px;
  }

  .mtoday_wid {
    display: inline-block;
    width: 49%;
  }

  .moderate_txt1 {
    position: relative;
    right: -70%;
  }

  .group_header43 {
    margin-top: 20px;
    padding: 12px 0px 4px 0px !important;
  }

  .group_header43 img {
    margin-left: -4px;
  }

  .group_header43 h5 {
    margin-left: 0px !important;
  }

  .group_header43 p {
    margin-left: 0px !important;
  }

  .group_header43 h5 button {
    width: auto !important;
    height: 38px !important;
    font-size: 12px !important;
    padding-left: 10px;
    padding-right: 10px;
    top: 0px !important;
  }

  .group_ftext6 {
    font-size: 14px !important;
  }

  .group_careteambtn {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .profile_circleinput {
    opacity: 0;
    margin-left: 52% !important;
  }

  .profile_circleimask {
    margin-left: 55% !important;
    margin-top: -70px;
  }

  /* end group section  */

  /* care plan start */
  .care_textdiv {
    padding: 10px 20px 20px 20px !important;
  }

  .button_top {
    margin-top: 90px !important;
    max-width: 60% !important;
  }

  .cancel_delete_blog {
    width: 42% !important;
    height: 46px !important;
    margin-right: 2% !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .delete_blog {
    width: 42% !important;
    height: 46px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .careplan_icontop {
    margin-top: 0px !important;
    text-align: left !important;
    margin-left: 9px;
  }

  .careplan_icontop2 {
    margin-top: 0px !important;
    text-align: left !important;
    margin-left: 9px;
  }

  .careplan_addtask {
    height: 45px;
    font-size: 14px;
    color: white;
    float: left !important;
    left: 0px !important;
    position: relative;
    margin-top: 0px;
  }

  .careplan_form99 {
    margin-top: 20px;
  }

  .careplan_addmore23 {
    width: 100%;
    height: 50px !important;
    border: 0;
    font-size: 15px !important;
    left: 0px !important;
  }

  .careplan_finalsave {
    width: 100% !important;
    height: 55px !important;
    border: 0;
    font-size: 16px !important;
    left: 0px !important;
  }

  .checkbox_caref1 {
    margin-top: 30px;
    float: left;
    top: 0px !important;
  }

  .caretask_cardpnew {
    margin-top: 20px !important;
    margin-bottom: -14px !important;
    margin-left: 40px !important;
    width: 88% !important;
  }

  .checkbox_access45 {
    top: -19px !important;
  }

  .caretask_allgoal3 button {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .careplan_filecard img {
    margin-bottom: 20px;
    float: left;
  }

  .active_assign_now {
    height: 40px !important;
    font-size: 13px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: -2px !important;
  }

  .careplantaskpopup {
    width: 100% !important;
    left: 0% !important;
  }

  .careplangoalpopup {
    width: 100% !important;
    left: 0% !important;
    top: 20px !important;
  }

  /* end care plan */

  /* daily journal start */

  .daily_journal {
    width: 100% !important;
    padding: 12px 0px 4px 0px !important;
  }

  .daily_journal img {
    height: 40px !important;
    width: 40px !important;
  }

  .daily_journal h5 {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-top: 5px !important;
    margin-left: -10px !important;
  }

  .daily_journal h5 span {
    margin-top: 6px !important;
  }

  .daily_journal h5 span .icon {
    font-size: 15px !important;
  }

  .daily_journal p {
    font-size: 11px !important;
  }

  .daily_cardsleep {
    padding: 15px 15px 15px 15px !important;
    margin-top: 20px;
  }

  .daily_cardsleep p {
    font-size: 15px !important;
  }

  .daily_cardsleep table tbody tr .table_title5 {
    font-size: 15px !important;
  }

  .daily_cardsleep table tbody tr {
    line-height: 22px !important;
  }

  .change_meal_plan {
    height: 45px !important;
    font-size: 13px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: -20px;
    font-weight: 500;
  }

  .diet_imgepara {
    position: relative;
    top: 25px;
    font-weight: 500;
  }

  .daily_journal_divwid {
    display: flex;
    flex-direction: column !important;
    margin-top: 0px !important;
    width: 100% !important;
  }

  .dailyjournal_textimp {
    height: 50px !important;
    margin-bottom: 10px;
    width: 100% !important;

    font-size: 15px !important;
  }

  .allpatient_btn9color {
    width: 100% !important;
    margin-right: 12px;
  }

  .mypatient_activeprogram7 img {
    float: left !important;
    margin-bottom: 15px;
  }

  .mt_patient_top9 {
    margin-top: 20px;
  }

  /* end daily journal */

  /* on process start */

  .onprocess99 {
    width: 70% !important;
    margin-left: 30% !important;
  }

  .onprocess99_cardinner {
    width: 130px;
    box-shadow: 0px 4px 20px #ccc9ca;
    border-radius: 12px;
    padding-top: 16px;
    padding-bottom: 10px;
    margin-bottom: 70px;
  }

  .patient_arrow_one {
    margin-top: 190px !important;
    position: relative;
    left: -90px;
    transform: rotate(90deg);
  }

  .pagination-footer {
    display: block !important;
    margin-top: 30px !important;
  }

  /* end onprocess */

  /* recipe start */
  .view_recipe_div img {
    min-height: auto !important;
    border-radius: 0px 0px 10px 10px !important;
  }

  .viewrecipetitle {
    font-size: 17px !important;
    font-weight: 500 !important;
    margin-top: 15px;
  }

  .viewrecipe_wid25 {
    display: inline-block;
    width: 50%;
    margin-right: -10px;
  }

  .recipe_meter {
    padding: 14px 10px 2px 10px !important;
    line-height: 3px !important;
    margin-bottom: 16px;
  }

  .viewrecipe_hr {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  .viewrecipe_hr1 {
    margin-top: 1px !important;
    margin-bottom: 20px !important;
  }

  .viewrecipe_server {
    margin-bottom: 7px;
    margin-top: -3px !important;
  }

  .viewrecipe_editbtn7 {
    height: 40px !important;
    font-size: 15px !important;
    float: none !important;
    margin-top: 15px !important;
    display: block;
  }

  .allpatient_seprate_btn {
    display: block;
  }

  /* end recipe */

  .blogfg_wid25 {
    width: 25%;
    display: inline-block;
  }

  .blogfg_wid70 {
    width: 70%;
    display: inline-block;
  }

  .topost_wid25 {
    width: 33%;
    display: inline-block;
  }

  .topost_wid70 {
    width: 62%;
    display: inline-block;
  }

  .post_container {
    width: 99% !important;
    margin-left: 0%;
  }

  .blogbtn_widfix {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

.whole_label {
  font-weight: 649;
  color: rgb(65, 62, 62);
  font-size: 1em;
  margin-bottom: 11px;
  letter-spacing: 0.3px;
  text-transform: capitalize !important;
}

.whole_label2 {
  font-weight: 649;
  color: rgb(65, 62, 62);
  font-size: 18px;

  letter-spacing: 0.3px;
  text-transform: capitalize !important;
}

.whole_labelx {
  font-weight: 649;
  color: rgb(65, 62, 62);
  font-size: 1em;
  margin-bottom: 11px;
  letter-spacing: 0.3px;
}

/* input:focus:invalid, select:focus:invalid {
  border: none;
  outline: 2px solid #e65656;
} */

.react-tel-input .form-control {
  border: none !important;
  width: 100% !important;
}

.react-tel-input .form-control:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.btn_radius45 {
  border-radius: 12px !important;
}

@media (min-width: 360px) and (max-width: 1025px) {
  .table_resouter {
    overflow-x: auto;
  }

  .blogs_listscroll5 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 840px;
  }
}

.message_headfour34 {
  font-size: 19px;
  color: rgb(143, 143, 143);
  font-weight: 700;
  letter-spacing: 0.4px;
}


.com_deletemodal88 {
  margin-top: 28vh;
  width: 85% !important;
  margin-left: auto;
  margin-right: auto;

}

.btnfix_wid81{
  width: 120px;
}
